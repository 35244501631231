module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"curenect","short_name":"curenect","start_url":"/","background_color":"#409AB5","theme_color":"#409AB5","display":"minimal-ui","icon":"src/images/icon.png","icons":[{"src":"/appicons/curenect-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/appicons/curenect-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/appicons/curenect-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/appicons/curenect-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/appicons/curenect-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/appicons/curenect-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/appicons/curenect-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/appicons/curenect-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9aeae76be435ece5810c33feb720ff83"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
